import { useEffect } from 'react'
import {
  primeraMayuscula,
  reemplazarAsteriscos,
  reemplazarGuionBajoPorSlash,
  reemplazarSlashPorGuionBajo,
} from 'src/utils/object'
import { Link, useNavigate, useParams } from 'react-router-dom'
import LoadingBox from 'src/components/LoadingBox'
import MessageBox from 'src/components/MessageBox'
import { useQuery } from '@tanstack/react-query'
import { getProductsTree } from 'src/api/products'
import { motion } from 'framer-motion'
import Pagination from 'src/components/Pagination'

export default function FilterProducts() {
  const params = useParams()
  const navigate = useNavigate()

  const { agrupName, marc, line, tipo } = params

  const searchParams = new URLSearchParams(location.search)
  const productPage = Number(searchParams.get('pagina')) || 1
  const productoQty = 9
  const { data, isLoading, error, isFetching, isFetched } = useQuery(
    ['products'],
    () => getProductsTree(`${agrupName}&${marc}&${line}&${tipo}`, productoQty, productPage),
    {
      retry: 3,
      retryDelay: 3000,
      refetchOnWindowFocus: true,
    },
  )
  const breadcrumbs = [
    { id: 1, name: 'Mundo de Hules', href: '/' },
    { id: 2, name: 'Inicio', href: '/hules' },
    { id: 3, name: data?.groups?.[0]?.agrupacion, href: `/hules/${agrupName}` },
    { id: 4, name: marc, href: `/hules/${agrupName}/${marc}` },
    { id: 5, name: line, href: `/hules/${agrupName}/${marc}/${line}` },
    { id: 6, name: tipo, href: `/hules/${agrupName}/${marc}/${line}/${tipo}/productos` },
  ]
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (isLoading || isFetching) {
    return (
      <span className='mt-3 flex justify-center items-center container mb-3 h-full'>
        <LoadingBox />
      </span>
    )
  }
  if (error instanceof Error) {
    return (
      <span className='mt-3 flex justify-center container mb-3'>
        <MessageBox variant='danger'>{error.message}</MessageBox>
      </span>
    )
  }
  if (data?.status === 'Error') {
    console.error(data?.status + ': ' + data.message)
    return (
      <span className='mt-3 flex justify-center container mb-3'>
        {/* <MessageBox variant='danger'>{data.message}</MessageBox> */}
        <MessageBox variant='danger'>Error de conexión. Recargue la página.</MessageBox>
      </span>
    )
  }
  return (
    <>
      <div className='bg-white'>
        <div>
          <div className='border-b border-gray-200'>
            <nav aria-label='Breadcrumb' className='mx-auto max-w-7xl sm:px-6 lg:px-8'>
              <ol role='list' className='flex flex-wrap items-center space-x-4 py-4'>
                {breadcrumbs.map((breadcrumb) =>
                  breadcrumb.name && breadcrumb.name.startsWith('*') ? null : (
                    <li key={breadcrumb.id}>
                      <div className='flex items-center'>
                        <Link
                          to={breadcrumb.href}
                          className='mr-4 text-sm font-medium text-gray-900'
                        >
                          {primeraMayuscula(breadcrumb.name || '')}
                        </Link>
                        <svg
                          viewBox='0 0 6 20'
                          aria-hidden='true'
                          className='h-5 w-auto text-gray-300'
                        >
                          <path
                            d='M4.878 4.34H3.551L.27 16.532h1.327l3.281-12.19z'
                            fill='currentColor'
                          />
                        </svg>
                      </div>
                    </li>
                  ),
                )}

                <li className='text-sm'>
                  <Link
                    to='#'
                    aria-current='page'
                    className='font-medium text-gray-500 hover:text-gray-600'
                  >
                    Productos
                  </Link>
                </li>
              </ol>
            </nav>
          </div>

          <section className='mt-4 -mb-10 ml-28'>
            <button
              type='button'
              onClick={() => navigate(-1)}
              className='hidden lg:block rounded-md bg-sky-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500'
            >
              Regresar
            </button>
          </section>
          <main className='mx-auto max-w-2xl px-4 md:max-w-5xl lg:max-w-7xl lg:px-8'>
            <div className='flex items-baseline justify-between border-b border-gray-200 pb-4 pt-24'>
              <h1 className='text-4xl font-bold tracking-tight text-gray-900'>
                Productos {reemplazarGuionBajoPorSlash(primeraMayuscula(tipo || ''))}
              </h1>

              <div className='flex items-center'></div>
            </div>

            <div className='pb-24 pt-12'>
              <section
                aria-labelledby='product-heading'
                className='mt-6 lg:col-span-2 lg:mt-0 xl:col-span-3'
              >
                <h2 id='product-heading' className='sr-only'>
                  Productos
                </h2>

                <div className='grid grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 md:grid-cols-3 lg:gap-x-8 lg:grid-cols-4 xl:grid-cols-4'>
                  {data?.products?.map((product, index: number) => {
                    return (
                      <motion.div
                        key={product.code}
                        className='group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white transition ease-in-out hover:-translate-y-1 hover:scale-105 duration-150'
                        initial={{ opacity: 0 }}
                        animate={isFetched ? { opacity: 1 } : {}} // Animación al mostrar los productos
                        transition={{ duration: 0.5, delay: isFetched ? index * 0.1 : 0 }} // Retraso progresivo para cada producto
                      >
                        <div className='aspect-h-4 aspect-w-3 border-b-2 bg-transparent sm:aspect-none group-hover:opacity-75 '>
                          <div className='h-full w-full object-cover object-center sm:h-full sm:w-full'>
                            {product.BImagen !== '' ? (
                              <div className='aspect-h-4 aspect-w-3 bg-transparent sm:aspect-none group-hover:opacity-75 sm:h-96'>
                                <img
                                  src={`data:image/jpg;base64,${product.BImagen}`}
                                  alt={product.code}
                                  className='h-full w-full object-contain object-center sm:h-full sm:w-full'
                                />
                              </div>
                            ) : (
                              <div className='grid place-content-center aspect-h-4 aspect-w-3 bg-gray-200 sm:aspect-none group-hover:opacity-75 sm:h-96'>
                                <div className='text-3xl'>Sin imagen</div>
                              </div>
                            )}
                            {/* <img
                            src={`data:image/jpg;base64,${product.BImagen}`}
                            alt={product.code}
                          /> */}
                          </div>
                        </div>
                        <div className='flex flex-1 flex-col space-y-2 p-4'>
                          <h3 className='text-sm font-medium text-gray-900'>
                            <Link
                              reloadDocument
                              to={`/hules/${agrupName}/${marc}/${line}/${tipo}/productos/${reemplazarSlashPorGuionBajo(
                                product.code,
                              )}`}
                            >
                              <span aria-hidden='true' className='absolute inset-0' />
                              {product.name}
                            </Link>
                          </h3>
                          {/* <p className='text-sm text-gray-500'>{product.desc}</p> */}
                          <div className='flex flex-1 flex-col justify-end'>
                            <p className='text-sm text-gray-500'>
                              {reemplazarAsteriscos(product.brand)}
                            </p>
                            <p className='text-base font-medium text-green-700'>
                              {' '}
                              $
                              <strong>
                                {Number(product.price?.replace(/,/g, '.'))
                                  .toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              </strong>{' '}
                              mxn
                            </p>
                          </div>
                        </div>
                      </motion.div>
                    )
                  })}
                </div>
                <div className='mt-5'>
                  <Pagination data={data} productoQty={productoQty} />
                </div>
              </section>
            </div>
            <section className='pb-5 ml-44 flex justify-end'>
              <button
                type='button'
                onClick={() => navigate(-1)}
                className='rounded-md bg-sky-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500'
              >
                Regresar
              </button>
            </section>
          </main>
        </div>
      </div>
    </>
  )
}
