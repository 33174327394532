import { CardPayment, StatusScreen } from '@mercadopago/sdk-react'
import { BillingForm } from '../components/BillingForm'

export default function BillingPage() {
  return (
    <>
      <BillingForm />
    </>
  )
}
