import { Fragment, useEffect, useState } from 'react'
import { Dialog, Disclosure, Transition } from '@headlessui/react'
import { FunnelIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon, PlusIcon } from '@heroicons/react/20/solid'
import { classNames, reemplazarSlashPorGuionBajo } from 'src/utils/object'
import { Link, useParams } from 'react-router-dom'
import LoadingBox from 'src/components/LoadingBox'
import MessageBox from 'src/components/MessageBox'
import { useQuery } from '@tanstack/react-query'
import { getCatalogos } from 'src/api/products'
import { motion } from 'framer-motion'
import Pagination from 'src/components/Pagination'

const breadcrumbs = [
  { id: 1, name: 'Moto Difer', href: 'https://motodifer.mx/' },
  { id: 2, name: 'Productos', href: '/' },
]
export default function SearchProducts() {
  const params = useParams()
  const { name } = params
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)
  const searchParams = new URLSearchParams(location.search)
  const productPage = Number(searchParams.get('pagina')) || 1
  const productoQty = 9
  const { data, isLoading, error, isFetching, isFetched } = useQuery(
    ['products', name, productoQty, productPage],
    getCatalogos,
    {
      retry: 3,
      retryDelay: 3000,
      refetchOnWindowFocus: false,
    },
  )
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const group = data?.groups?.map((g) => {
    const obj = {
      nombre: g.agrupacion,
      cantidad: g.cantidad,
      href: `/categoria/${g.clase}`,
    }
    return obj
  })
  const brand = data?.brands?.map((g) => {
    const obj = {
      nombre: g.marca,
      cantidad: g.cantidad,
      href: `/marcas/${g.marca}`,
    }
    return obj
  })

  const filters = [
    {
      id: 'category',
      name: 'Categoría',
      options: group,
    },
    {
      id: 'brand',
      name: 'Marca',
      options: brand,
    },
  ]
  if (isLoading || isFetching) {
    return (
      <span className='mt-3 flex justify-center items-center container mb-3 h-full'>
        <LoadingBox />
      </span>
    )
  }

  if (error instanceof Error) {
    return (
      <span className='mt-3 flex justify-center container mb-3'>
        <MessageBox variant='danger'>{error.message}</MessageBox>
      </span>
    )
  }

  if (data?.status === 'Error') {
    console.error(data?.status + ': ' + data.message)
    return (
      <span className='mt-3 flex justify-center container mb-3'>
        {/* <MessageBox variant='danger'>{data.message}</MessageBox> */}
        <MessageBox variant='danger'>Error de conexión. Recargue la página.</MessageBox>
      </span>
    )
  }

  return (
    <>
      <div className='bg-white'>
        <div>
          {/* Mobile filter dialog */}
          <Transition.Root show={mobileFiltersOpen} as={Fragment}>
            <Dialog as='div' className='relative z-40 lg:hidden' onClose={setMobileFiltersOpen}>
              <Transition.Child
                as={Fragment}
                enter='transition-opacity ease-linear duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='transition-opacity ease-linear duration-300'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 bg-black bg-opacity-25' />
              </Transition.Child>

              <div className='fixed inset-0 z-40 flex'>
                <Transition.Child
                  as={Fragment}
                  enter='transition ease-in-out duration-300 transform'
                  enterFrom='translate-x-full'
                  enterTo='translate-x-0'
                  leave='transition ease-in-out duration-300 transform'
                  leaveFrom='translate-x-0'
                  leaveTo='translate-x-full'
                >
                  <Dialog.Panel className='relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl'>
                    <div className='flex items-center justify-between px-4'>
                      <h2 className='text-lg font-medium text-gray-900'>Filtros</h2>
                      <button
                        type='button'
                        className='-mr-2 flex h-10 w-10 items-center justify-center p-2 text-gray-400 hover:text-gray-500'
                        onClick={() => setMobileFiltersOpen(false)}
                      >
                        <span className='sr-only'>Close menu</span>
                        <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                      </button>
                    </div>

                    {/* Filters */}
                    <form className='mt-4'>
                      {filters.map((section) => (
                        <Disclosure
                          as='div'
                          key={section.name}
                          className='border-t border-gray-200 pb-4 pt-4'
                        >
                          {({ open }) => (
                            <fieldset>
                              <legend className='w-full px-2'>
                                <Disclosure.Button className='flex w-full items-center justify-between p-2 text-gray-400 hover:text-gray-500'>
                                  <span className='text-sm font-medium text-gray-900'>
                                    {section.name}
                                  </span>
                                  <span className='ml-6 flex h-7 items-center'>
                                    <ChevronDownIcon
                                      className={classNames(
                                        open ? '-rotate-180' : 'rotate-0',
                                        'h-5 w-5 transform',
                                      )}
                                      aria-hidden='true'
                                    />
                                  </span>
                                </Disclosure.Button>
                              </legend>
                              <Disclosure.Panel className='px-4 pb-2 pt-4'>
                                <div className='space-y-6'>
                                  {section?.options?.map((option, optionIdx) => (
                                    <div key={option.nombre} className='flex items-center'>
                                      <Link to={option.href} className='ml-3 text-sm text-gray-500'>
                                        {option.nombre}
                                      </Link>
                                    </div>
                                  ))}
                                </div>
                              </Disclosure.Panel>
                            </fieldset>
                          )}
                        </Disclosure>
                      ))}
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          <div className='border-b border-gray-200'>
            <nav aria-label='Breadcrumb' className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
              <ol role='list' className='flex items-center space-x-4 py-4'>
                {breadcrumbs.map((breadcrumb) => (
                  <li key={breadcrumb.id}>
                    <div className='flex items-center'>
                      <Link to={breadcrumb.href} className='mr-4 text-sm font-medium text-gray-900'>
                        {breadcrumb.name}
                      </Link>
                      <svg
                        viewBox='0 0 6 20'
                        aria-hidden='true'
                        className='h-5 w-auto text-gray-300'
                      >
                        <path
                          d='M4.878 4.34H3.551L.27 16.532h1.327l3.281-12.19z'
                          fill='currentColor'
                        />
                      </svg>
                    </div>
                  </li>
                ))}
                <li className='text-sm'>
                  <button
                    aria-current='page'
                    className='font-medium text-gray-500 hover:text-gray-600'
                  >
                    Resultado de búsqueda de &quot;{name?.toUpperCase()}&quot;
                  </button>
                </li>
              </ol>
            </nav>
          </div>

          <main className='mx-auto max-w-2xl px-4 lg:max-w-7xl lg:px-8'>
            <div className='flex items-baseline justify-between border-b border-gray-200 pb-4 pt-24'>
              <div>
                <h1 className='text-4xl font-bold tracking-tight text-gray-900'>
                  Resultados de búsqueda
                </h1>
                <p className='text-base text-gray-500'>
                  {data?.products?.length} RESULTADOS DE:{' '}
                  <span className='font-semibold text-[#F20505]'>{name?.toUpperCase()}</span>
                </p>
              </div>
              <div className='flex items-center'>
                <button
                  type='button'
                  className='-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden'
                  onClick={() => setMobileFiltersOpen(true)}
                >
                  <span className='sr-only'>Filtros</span>
                  <FunnelIcon className='h-5 w-5' aria-hidden='true' />
                </button>
              </div>
            </div>

            <div className='pb-24 pt-12'>
              {/* <aside>
                <h2 className='sr-only'>Filtros</h2>

                <button
                  type='button'
                  className='inline-flex items-center lg:hidden'
                  onClick={() => setMobileFiltersOpen(true)}
                >
                  <span className='text-sm font-medium text-gray-700'>Filtros</span>
                  <PlusIcon
                    className='ml-1 h-5 w-5 flex-shrink-0 text-gray-400'
                    aria-hidden='true'
                  />
                </button>
                <div className='hidden lg:block'>
                  <form className='space-y-10 divide-y divide-gray-200'>
                    {filters.map((section, sectionIdx) => (
                      <div key={section.name} className={sectionIdx === 0 ? '' : 'pt-10'}>
                        <fieldset>
                          <legend className='block text-sm font-medium text-gray-900'>
                            {section.name}
                          </legend>
                          <div className='space-y-3 pt-6'>
                            {section.options?.map((option, optionIdx) => (
                              <div key={option.nombre} className='flex items-center'>
                                <Link
                                  to={option.href}
                                  className='ml-3 decoration-inherit text-sm text-gray-600'
                                >
                                  {option.nombre}
                                </Link>
                              </div>
                            ))}
                          </div>
                        </fieldset>
                      </div>
                    ))}
                  </form>
                </div>
              </aside> */}

              <section
                aria-labelledby='product-heading'
                className='mt-6 lg:col-span-2 lg:mt-0 xl:col-span-3'
              >
                <h2 id='product-heading' className='sr-only'>
                  Resultados de búsqueda
                </h2>

                <div className='grid grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 md:grid-cols-3 lg:gap-x-8 xl:grid-cols-4'>
                  {data?.products?.map((product, index) => {
                    return (
                      <motion.div
                        key={product.code}
                        className='group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white transition ease-in-out hover:-translate-y-1 hover:scale-105 duration-150'
                        initial={{ opacity: 0 }}
                        animate={isFetched ? { opacity: 1 } : {}} // Animación al mostrar los productos
                        transition={{
                          duration: 0.5,
                          delay: isFetched ? index * 0.1 : 0,
                        }} // Retraso progresivo para cada producto
                      >
                        <div className='aspect-h-4 aspect-w-3 border-b-2 bg-transparent sm:aspect-none group-hover:opacity-75 '>
                          <div className='h-full w-full object-cover object-center sm:h-full sm:w-full'>
                            {product.BImagen !== '' ? (
                              <div className='aspect-h-4 aspect-w-3 bg-transparent sm:aspect-none group-hover:opacity-75 sm:h-96'>
                                <img
                                  src={`data:image/jpg;base64,${product.BImagen}`}
                                  alt={product.code}
                                  className='h-full w-full object-contain object-center sm:h-full sm:w-full'
                                />
                              </div>
                            ) : (
                              <div className='grid place-content-center aspect-h-4 aspect-w-3 bg-gray-200 sm:aspect-none group-hover:opacity-75 sm:h-96'>
                                <div className='text-3xl'>Sin imagen</div>
                              </div>
                            )}
                            {/* <img
                            src={`data:image/jpg;base64,${product.BImagen}`}
                            alt={product.code}
                          /> */}
                          </div>
                        </div>
                        <div className='flex flex-1 flex-col space-y-2 p-4'>
                          <h3 className='text-sm font-medium text-gray-900'>
                            <Link
                              reloadDocument
                              to={`/productos/${reemplazarSlashPorGuionBajo(product.code)}`}
                            >
                              <span aria-hidden='true' className='absolute inset-0' />
                              {product.name}
                            </Link>
                          </h3>
                          {/* <p className='text-sm text-gray-500'>{product.desc}</p> */}
                          <div className='flex flex-1 flex-col justify-end'>
                            <p className='text-sm text-gray-500'>{product.brand}</p>
                            <p className='text-base font-medium text-green-700'>
                              {' '}
                              $
                              <strong>
                                {Number(product.price?.replace(/,/g, '.'))
                                  .toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              </strong>{' '}
                              mxn
                            </p>
                          </div>
                        </div>
                      </motion.div>
                    )
                  })}
                </div>
                <div className='mt-5'>
                  <Pagination query={name && `${name}`} data={data} productoQty={productoQty} />
                </div>
              </section>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}
