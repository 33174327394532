import z from 'zod'

/** Save Billing */
export const billingFormSchema = z.object({
  nombreFiscal: z
    .string({ required_error: 'No deje vacío este campo' })
    .min(10, 'Escriba su nombre completo'),
  regimenFiscal: z
    .string({ required_error: 'No deje vacío este campo' })
    .min(1, 'Escriba un teléfono válido'),
  rfc: z
    .string({ required_error: 'No deje vacío este campo' })
    .min(10, 'Escriba un rfc válido')
    .max(13, 'Escriba un rfc válido'),
  usoCfdi: z
    .string({ required_error: 'No deje vacío este campo' })
    .min(1, 'Escriba una dirección de envío válida'),
  formaPago: z
    .string({ required_error: 'No deje vacío este campo' })
    .min(1, 'Escriba una dirección de envío válida'),
  cp: z
    .string({ required_error: 'No deje vacío este campo' })
    .min(1, 'No deje vacío este campo')
    .max(10, 'Escriba un número válido'),
})

export type BillingUserFormValues = z.infer<typeof billingFormSchema>
