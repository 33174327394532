import Hules from '../img/7000hules.jpg'
import Mundos from '../img/9mundos.jpg'
import Fabricantes from '../img/soportes_hule.jpg'
import Industrial from '../img/hules-autocamion.png'
import Tractocamion from '../img/tractocamion.png'
import Pasillo from '../img/hules_pasillo_antiderrapantes.png'
import Cotiza from '../img/Por si los hules.png'
import Bandas from '../img/bujes-y-tirantes.png'
import Mangueras from '../img/MANGUERAS-MOLDEADAS.jpg'
import Neoprenos from '../img/neoprenos-y-nitrilos.png'
import { Link } from 'react-router-dom'

const categories = [
  {
    name: 'Automotriz',
    href: `A`,
    imageSrc: `https://mundohules.adaflex.mx:4430/imagenes/grupos/AUTOMOTRIZ.png`,
  },
  {
    name: 'Complementos',
    href: 'D/*COMPLEMENTOS',
    imageSrc: `https://mundohules.adaflex.mx:4430/imagenes/grupos/COMPLEMENTOS.png`,
  },
  {
    name: 'Industrial',
    href: 'C/*INDUSTRIAL',
    imageSrc: `https://mundohules.adaflex.mx:4430/imagenes/grupos/INDUSTRIAL.png`,
  },
  {
    name: 'Perfiles',
    href: `E/*PERFILES`,
    imageSrc: `https://mundohules.adaflex.mx:4430/imagenes/grupos/PERFILES.png`,
  },
  // {
  //   name: 'Carga Pesada',
  //   href: '#',
  //   imageSrc: `https://mundohules.adaflex.mx:4430/imagenes/grupos/CARGA_PESADA.png`,
  // },
]
const collections = [
  {
    name: 'Hules industriales',
    href: 'http://www.mundodehules.com/catalogos/HULES_INDUSTRIALES_MDH_2016.pdf',
    imageSrc: Industrial,
    imageAlt: 'Neoprenos, Nitrilos, SBR, Dilectrico, Ambar, Nitrilo Sanitario.',
    description: 'Neoprenos, Nitrilos, SBR, Dilectrico, Ambar, Nitrilo Sanitario.',
  },
  {
    name: 'Hules de pasillos',
    href: 'http://www.mundodehules.com/catalogos/hules_pasillo_antiderrapantes.pdf',
    imageSrc: Pasillo,
    imageAlt:
      'Hules pasillos tramados, Linoleums, Hules pasillos acabado, Rugos, Alfombras, Espaguetti',
    description:
      'Hules pasillos tramados, Linoleums, Hules pasillos acabado, Rugos, Alfombras, Espaguetti',
  },
  {
    name: 'Heavy Duty',
    href: 'http://www.mundodehules.com/catalogos/HULES-TRACTOCAMION-OP.pdf',
    imageSrc: Tractocamion,
    imageAlt: 'Núcleos, Bujes, Ganchos, Soportes, Corbatas, Codos, Coples',
    description: 'Núcleos, Bujes, Ganchos, Soportes, Corbatas, Codos, Coples',
  },
  {
    name: 'Bandas automotrices',
    href: 'https://na.daycoaftermarket.com/es/',
    imageSrc: Bandas,
    imageAlt:
      'Audi, Chevrolet, Dodge, Ford, Honda, Hummer, Isuzu, Jeep, Mazda, Nissan, Peugeot, Rambler, Renault, Seat, Toyota, Volkswagen.',
    description:
      'Audi, Chevrolet, Dodge, Ford, Honda, Hummer, Isuzu, Jeep, Mazda, Nissan, Peugeot, Rambler, Renault, Seat, Toyota, Volkswagen',
  },
  {
    name: 'Mangueras moldeadas',
    href: 'http://www.mundodehules.com/catalogos/Cauplas.pdf',
    imageSrc: Mangueras,
    imageAlt:
      'Refrigeración, Calefacción, Radiador, Combustible, Descarga de gases, Circulación de aire, Automotrices, Equipo pesado.',
    description:
      'Refrigeración, Calefacción, Radiador, Combustible, Descarga de gases, Circulación de aire, Automotrices, Equipo pesado.',
  },
  {
    name: 'Complementos industriales',
    href: 'http://www.mundodehules.com/catalogos/COMPLEMENTOS_INDUSTRIALES.pdf',
    imageSrc: Neoprenos,
    imageAlt:
      'Botas industriales, Recubrimiento texturizado, Pegamentos industriales, Topes de estacionamiento.',
    description:
      'Botas industriales, Recubrimiento texturizado, Pegamentos industriales, Topes de estacionamiento.',
  },
]

export default function Landing() {
  return (
    <div className='bg-white'>
      {/* Hero section */}
      <div className='relative -mb-60 lg:mb-0 lg:bg-gray-900  xl:h-[450px]'>
        {/* Decorative image and overlay */}
        <div aria-hidden='true' className='absolute inset-0 overflow-hidden'>
          <img
            // src='https://tailwindui.com/img/ecommerce-images/home-page-01-hero-full-width.jpg'
            src={Mundos}
            alt=''
            className='h-[50%] lg:h-full w-full object-cover object-center xl:h-full'
          />
        </div>
        <div aria-hidden='true' className='absolute inset-0' />

        <div className='relative mx-auto flex max-w-3xl flex-col items-end px-6 py-32 text-left sm:py-44 lg:px-0'>
          <Link to='/sucursales'>
            <h1 className='text-4xl font-bold tracking-tight text-transparent lg:text-6xl'>
              Por si los hules...
            </h1>
            <p className='mt-4 text-xl text-transparent'>
              Un mundo de opciones en hules para su auto, taller, camión e industrial
            </p>
            <p className='mt-8 inline-block rounded-md bg-transparent px-8 py-3 text-base font-medium text-transparent hover:bg-transparent'>
              Ver hules
            </p>
          </Link>
        </div>
      </div>

      <main className='xl:px-44'>
        {/* Category section */}
        <section
          aria-labelledby='category-heading'
          className='pt-24 sm:pt-32 xl:mx-auto xl:max-w-7xl xl:px-8'
        >
          <div className='px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 xl:px-0'>
            <h2 id='category-heading' className='text-2xl font-bold tracking-tight text-gray-900'>
              Buscar hules por categoría
            </h2>
            <a
              href='/hules'
              className='hidden text-sm font-semibold text-indigo-600 hover:text-indigo-500 sm:block'
            >
              Ver categorías
              <span aria-hidden='true'> &rarr;</span>
            </a>
          </div>

          <div className='mt-4 flow-root'>
            <div className='-my-2 px-4'>
              <div className='grid grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:gap-x-8 xl:grid-cols-4'>
                {categories.map((category) => (
                  <a
                    key={category.name}
                    href={`/hules/${category.href}`}
                    className='group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white transition ease-in-out hover:-translate-y-1 hover:scale-105 duration-150 h-80 p-6 hover:opacity-75 xl:w-auto'
                  >
                    <span aria-hidden='true' className='absolute inset-0'>
                      <img
                        src={category.imageSrc}
                        alt=''
                        className='h-full w-full object-cover object-center'
                      />
                    </span>
                    <span
                      aria-hidden='true'
                      className='absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50'
                    />
                    <span className='relative mt-auto text-center text-xl font-bold text-white'>
                      {category.name}
                    </span>
                  </a>
                ))}
              </div>
            </div>
          </div>

          <div className='mt-6 px-4 sm:hidden'>
            <a
              href='/hules'
              className='block text-sm font-semibold text-indigo-600 hover:text-indigo-500'
            >
              Ver categorías
              <span aria-hidden='true'> &rarr;</span>
            </a>
          </div>
        </section>

        {/* Featured section */}
        <section
          aria-labelledby='social-impact-heading'
          className='mx-auto max-w-7xl pt-24 sm:px-6 sm:pt-32 lg:px-8 transition ease-in-out hover:-translate-y-1 hover:scale-105 duration-150'
        >
          <Link to='/hules'>
            <div className='relative -mb-40 lg:mb-0 overflow-hidden lg:border lg:shadow-md rounded-lg'>
              <div className='absolute inset-0'>
                <img
                  src={Hules}
                  alt=''
                  className='h-[65%] lg:h-full w-full object-cover '
                  style={{ objectPosition: '-200px 0px' }}
                />
              </div>
              <div className='relative px-6 py-32 sm:px-12 sm:py-40 lg:px-16 xl:py-24'>
                <div className='relative mx-auto h-40 flex max-w-3xl flex-col items-center text-center'></div>
              </div>
            </div>
          </Link>
        </section>

        {/* Collection section */}
        <section
          aria-labelledby='collection-heading'
          className='mx-auto max-w-xl px-4 pt-24 sm:px-6 sm:pt-32 lg:max-w-7xl lg:px-8'
        >
          <div className='px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 xl:px-0'>
            <h2 id='category-heading' className='text-2xl font-bold tracking-tight text-gray-900'>
              Nuestros catálogos
            </h2>
            <a
              href='/catalogos'
              className='hidden text-sm font-semibold text-indigo-600 hover:text-indigo-500 sm:block'
            >
              Ver catálogos
              <span aria-hidden='true'> &rarr;</span>
            </a>
          </div>

          <p className='px-4 mt-4 text-base text-gray-500'>
            Seguimos subiendo todos nuestros productos. Para ver todo lo que tenemos, te invitamos a
            revisar nuestros catálogos.
          </p>

          <div className='mt-10 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0 xl:px-12'>
            {collections.map((collection) => (
              <a
                key={collection.name}
                href={collection.href}
                target='_blank'
                className='group block'
                rel='noopener'
              >
                <div
                  aria-hidden='true'
                  className='aspect-h-2 aspect-w-3 overflow-hidden rounded-lg lg:aspect-h-3 lg:aspect-w-5 group-hover:opacity-75 transition ease-in-out hover:-translate-y-1 hover:scale-105 duration-150'
                >
                  <img
                    src={collection.imageSrc}
                    alt={collection.imageAlt}
                    className='h-full w-full object-cover object-center'
                  />
                </div>
                <h3 className='mt-4 text-base font-semibold text-gray-900'>{collection.name}</h3>
                <p className='mt-2 text-sm text-gray-500'>{collection.description}</p>
              </a>
            ))}
          </div>
        </section>

        {/* Featured section */}
        <section
          aria-labelledby='comfort-heading'
          className='mx-auto max-w-7xl pt-24 sm:px-6 sm:pt-32 lg:px-8 transition ease-in-out hover:-translate-y-1 hover:scale-105 duration-150'
        >
          <Link to='/hules'>
            <div className='relative overflow-hidden rounded-lg'>
              <div className='absolute inset-0'>
                <img
                  src={Fabricantes}
                  alt=''
                  className='h-[70%] lg:h-full w-full object-cover '
                  style={{ objectPosition: '-225px 0px' }}
                />
              </div>
              <div className='relative px-6 py-32 sm:px-12 sm:py-40 lg:px-16 xl:py-24'>
                <div className='relative h-40 mx-auto flex max-w-3xl flex-col items-center text-center'></div>
              </div>
            </div>
          </Link>
        </section>

        {/* Featured section */}
        <section
          aria-labelledby='comfort-heading'
          className='mx-auto pb-20 max-w-7xl pt-24 sm:px-6 sm:pt-32 lg:px-8 transition ease-in-out hover:-translate-y-1 hover:scale-105 duration-150'
        >
          <a href='https://wa.me/5213317358168' target='_blank'>
            <div className='relative overflow-hidden rounded-lg'>
              <div className='absolute inset-0'>
                <img
                  src={Cotiza}
                  alt=''
                  className='h-[70%] lg:h-full w-full object-cover '
                  style={{ objectPosition: '-1px 0px' }}
                />
              </div>
              <div className='relative px-6 py-32 sm:px-12 sm:py-40 lg:px-16 xl:py-24'>
                <div className='relative h-40 mx-auto flex max-w-3xl flex-col items-center text-center'></div>
              </div>
            </div>
          </a>
        </section>
      </main>
    </div>
  )
}
