import { Outlet, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { classNames } from '../utils/object'
import { HomeIcon, KeyIcon, UserCircleIcon } from '@heroicons/react/24/outline'

// const reducer = (state, action) => {
//   switch (action.type) {
//     case 'UPDATE_REQUEST':
//       return { ...state, loadingUpdate: true };
//     case 'UPDATE_SUCCESS':
//       return { ...state, loadingUpdate: false };
//     case 'UPDATE_FAIL':
//       return { ...state, loadingUpdate: false };

//     default:
//       return state;
//   }
// };

const subNavigation = [
  { name: 'Perfil', href: '/cuenta', icon: UserCircleIcon, current: true },
  { name: 'Contraseña', href: '/cuenta/password', icon: KeyIcon, current: false },
  { name: 'Datos de envío', href: '/cuenta/domicilio', icon: HomeIcon, current: false },
  // { name: 'Notifications', href: '#', icon: BellIcon, current: false },
  // { name: 'Billing', href: '#', icon: CreditCardIcon, current: false },
  // { name: 'Integrations', href: '#', icon: SquaresPlusIcon, current: false },
]

export default function InfoCuenta() {
  const location = useLocation()

  return (
    <>
      <div
        aria-hidden='true'
        className={classNames(
          // open ? 'bottom-0' : 'inset-y-0',
          'absolute inset-x-0 left-1/2 w-full -translate-x-1/2 transform overflow-hidden lg:h-[25vh]',
        )}
      >
        <div className='absolute inset-0 flex'>
          <div className='h-full w-1/2' style={{ backgroundColor: '#0a527b' }} />
          <div className='h-full w-1/2' style={{ backgroundColor: '#065d8c' }} />
        </div>
        <div className='relative flex justify-center'>
          <svg className='shrink-0' width={1750} height={308} viewBox='0 0 1750 308'>
            <path d='M284.161 308H1465.84L875.001 182.413 284.161 308z' fill='#0369a1' />
            <path d='M1465.84 308L16.816 0H1750v308h-284.16z' fill='#065d8c' />
            <path d='M1733.19 0L284.161 308H0V0h1733.19z' fill='#0a527b' />
            <path d='M875.001 182.413L1733.19 0H16.816l858.185 182.413z' fill='#0a4f76' />
          </svg>
        </div>
      </div>
      <header className='relative py-10'>
        <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <h1 className='text-3xl font-bold tracking-tight text-white'>Ajustes</h1>
        </div>
      </header>
      <main className='relative'>
        <div className='mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16'>
          <div className='overflow-hidden rounded-lg bg-white shadow'>
            <div className='divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-x lg:divide-y-0'>
              <aside className='py-6 lg:col-span-3'>
                <nav className='space-y-1'>
                  {subNavigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.href === location.pathname
                          ? 'border-teal-500 bg-teal-50 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                          : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                        'group flex items-center border-l-4 px-3 py-2 text-sm font-medium',
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      <item.icon
                        className={classNames(
                          item.href === location.pathname
                            ? 'text-teal-500 group-hover:text-teal-500'
                            : 'text-gray-400 group-hover:text-gray-500',
                          '-ml-1 mr-3 h-6 w-6 flex-shrink-0',
                        )}
                        aria-hidden='true'
                      />
                      <span className='truncate'>{item.name}</span>
                    </Link>
                  ))}
                </nav>
              </aside>
              <Outlet />
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
