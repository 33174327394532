//import Spinner from "react-bootstrap/Spinner";
import PropagateLoader from 'react-spinners/PropagateLoader'

export default function LoadingBox() {
  return (
    <div className='loader h-[100vh]'>
      <PropagateLoader size={25} color='#36d7b7'>
        {' '}
        <span className='visually-hidden'>Cargando...</span>
      </PropagateLoader>
    </div>
  )
}
