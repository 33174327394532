import { useEffect } from 'react'
import { reemplazarSlashPorGuionBajo } from 'src/utils/object'
import { Link } from 'react-router-dom'
import LoadingBox from 'src/components/LoadingBox'
import MessageBox from 'src/components/MessageBox'
import { useQuery } from '@tanstack/react-query'
import { getProductsTree } from 'src/api/products'

export default function FilterAgrup() {
  const searchParams = new URLSearchParams(location.search)
  const productPage = Number(searchParams.get('pagina')) || 1
  const productoQty = 1
  const { data, isLoading, error, isFetching } = useQuery(
    ['products'],
    () => getProductsTree('null', productoQty, productPage),
    {
      retry: 3,
      retryDelay: 3000,
      refetchOnWindowFocus: true,
    },
  )
  const breadcrumbs = [
    { id: 1, name: 'Mundo de Hules', href: '/' },
    { id: 2, name: 'Inicio', href: '/hules' },
  ]

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const categories = data?.groups?.map((p) => ({
    agrupacion: p.agrupacion,
    clase: p.clase,
    imagen: `https://mundohules.adaflex.mx:4430/imagenes/grupos/${p.agrupacion}.png`,
  }))
  if (isLoading || isFetching) {
    return (
      <span className='mt-3 flex justify-center items-center container mb-3 h-full'>
        <LoadingBox />
      </span>
    )
  }
  if (error instanceof Error) {
    return (
      <span className='mt-3 flex justify-center container mb-3'>
        <MessageBox variant='danger'>{error.message}</MessageBox>
      </span>
    )
  }

  if (data?.status === 'Error') {
    console.error(data?.status + ': ' + data.message)
    return (
      <span className='mt-3 flex justify-center container mb-3'>
        {/* <MessageBox variant='danger'>{data.message}</MessageBox> */}
        <MessageBox variant='danger'>Error de conexión. Recargue la página.</MessageBox>
      </span>
    )
  }

  return (
    <>
      <div className='bg-white'>
        <div>
          <div className='border-b border-gray-200'>
            <nav aria-label='Breadcrumb' className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
              <ol role='list' className='flex flex-wrap items-center space-x-4 py-4'>
                {breadcrumbs.map((breadcrumb) => (
                  <li key={breadcrumb.id}>
                    <div className='flex items-center'>
                      <Link to={breadcrumb.href} className='mr-4 text-sm font-medium text-gray-900'>
                        {breadcrumb.name}
                      </Link>
                      <svg
                        viewBox='0 0 6 20'
                        aria-hidden='true'
                        className='h-5 w-auto text-gray-300'
                      >
                        <path
                          d='M4.878 4.34H3.551L.27 16.532h1.327l3.281-12.19z'
                          fill='currentColor'
                        />
                      </svg>
                    </div>
                  </li>
                ))}
              </ol>
            </nav>
          </div>

          <main className='mx-auto max-w-2xl px-4 md:max-w-5xl lg:max-w-7xl lg:px-8'>
            <div className='flex items-baseline justify-between border-b border-gray-200 pb-4 pt-12'>
              <h1 className='text-4xl font-bold tracking-tight text-gray-900'>Productos</h1>
            </div>

            <div className='pb-24 pt-12'>
              <section aria-labelledby='product-heading' className='mt-6 lg:mt-0'>
                <h2 id='product-heading' className='sr-only'>
                  Productos
                </h2>

                <div className='grid grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 md:grid-cols-3 lg:gap-x-8 lg:grid-cols-4 xl:grid-cols-4'>
                  {categories?.map(
                    (category) =>
                      category.clase !== '' && (
                        <a
                          key={category.agrupacion}
                          href={
                            category.clase === 'A'
                              ? `/hules/${reemplazarSlashPorGuionBajo(category.clase)}`
                              : `/hules/${reemplazarSlashPorGuionBajo(category.clase)}/*${
                                  category.agrupacion
                                }`
                          }
                          className='group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white transition ease-in-out hover:-translate-y-1 hover:scale-105 duration-150 h-80 p-6 hover:opacity-75 xl:w-auto'
                        >
                          <span aria-hidden='true' className='absolute inset-0'>
                            <img
                              src={category.imagen}
                              alt={category.agrupacion}
                              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                ;(e.target as HTMLImageElement).src =
                                  'https://mundohules.adaflex.mx:4430/imagenes/logo.png'
                                ;(e.target as HTMLImageElement).className =
                                  'w-full px-16 pt-16 object-contain object-center'
                              }}
                              className='h-full w-full object-contain object-center'
                            />
                          </span>
                          <span
                            aria-hidden='true'
                            className='absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50'
                          />
                          <span className='relative mt-auto text-base md:text-lg text-center lg:text-xl font-bold text-white'>
                            {category.agrupacion}
                          </span>
                        </a>
                      ),
                  )}
                </div>
              </section>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}
