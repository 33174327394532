import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useContext, useEffect, useState } from 'react'
import { Store } from '../Store'
import { toast } from 'react-toastify'
import { zodResolver } from '@hookform/resolvers/zod'
import Icono from '../components/img/icono.png'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { genCodeRecovery, signIn } from '../api/user'
import { classNames } from '../utils/object'
import { ExclamationCircleIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import secureLocalStorage from 'react-secure-storage'
import { useForm } from 'react-hook-form'
import { DevTool } from '@hookform/devtools'

import { recoverUserFormSchema, type RecoverUserFormValues } from '../schemas/loginSchema'
import type { User } from 'types/api'
import type { ACTIONTYPE } from '../Store'
import axios from 'axios'

type Context = {
  state: {
    userInfo: User
  }
  dispatch: React.Dispatch<ACTIONTYPE>
}

const RecPass = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  // const redirectInUrl = new URLSearchParams(search).get('redirect')
  // const redirect = redirectInUrl ? redirectInUrl : '/productos'

  const { state, dispatch: ctxDispatch }: Context = useContext(Store)
  const [successSent, setSuccessSent] = useState(false)

  const { userInfo } = state

  const queryClient = useQueryClient()
  const userData = useMutation(genCodeRecovery, {
    onSuccess: (data, variables) => {
      void queryClient.invalidateQueries(['users'])
      if (data?.status === 'Success') {
        toast.success(data?.mensaje as string)
        setSuccessSent(true)
        // ctxDispatch({ type: 'USER_SIGNIN', payload: data })
        // secureLocalStorage.setItem('userInfo', JSON.stringify(data))
        // secureLocalStorage.setItem('pf', JSON.stringify(variables))
        // navigate('/codigo_recuperar')
        // location.reload()
      }
    },
    onError: (error) => {
      toast.error(error instanceof Error)
      setSuccessSent(false)
    },
  })

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<RecoverUserFormValues>({
    resolver: zodResolver(recoverUserFormSchema),
  })

  const submitHandler = async (data: RecoverUserFormValues) => {
    const { username } = data
    await userData.mutateAsync(username)
  }

  function getError(field: keyof RecoverUserFormValues) {
    if (errors[field]) {
      return errors[field]?.message
    }
    return undefined
  }

  if (userData.data?.status === 'Error') {
    toast.error(userData.data.message)
    toast.clearWaitingQueue()
  }

  // useEffect(() => {
  //   if (userInfo) {
  //     navigate(redirect)
  //   }
  // }, [navigate, redirect, userInfo])

  return (
    <>
      <Helmet>
        <title>Ingreso</title>
      </Helmet>
      <div className='flex min-h-full flex-col justify-center bg-blue-900 py-12 sm:px-6 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-md'>
          <Link to='/'>
            <img className='mx-auto h-12 w-auto' src={Icono} alt='Mundo de Hules sistemas' />
          </Link>
          <h2 className='mt-6 text-center text-3xl font-bold tracking-tight text-gray-200'>
            Recuperar contraseña
          </h2>
          <p className='mt-2 text-center text-sm text-gray-300'>
            <Link to={`/ingresar`} className='font-medium text-indigo-300 hover:text-indigo-200'>
              Volver
            </Link>
          </p>
        </div>

        <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
          <div className='bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10'>
            <form className='space-y-6' onSubmit={handleSubmit(submitHandler)}>
              <div>
                <label
                  htmlFor='username'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Correo electrónico
                </label>
                <div className=' relative mt-2'>
                  <input
                    id='username'
                    {...register('username')}
                    type='text'
                    className={classNames(
                      getError('username')
                        ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                        : 'text-gray-900 ring-gray-300 focus:ring-indigo-600 placeholder:text-gray-400',
                      'block w-full rounded-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6',
                    )}
                  />
                  {getError('username') && (
                    <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
                      <ExclamationCircleIcon className='h-5 w-5 text-red-500' aria-hidden='true' />
                    </div>
                  )}
                </div>
                {getError('username') && (
                  <p className='mt-2 text-sm text-red-600' id='email-error'>
                    {getError('username')}
                  </p>
                )}
              </div>

              <div className='flex items-center justify-between'>
                {/* <div className='flex items-center'>
                  <input
                    id='remember-me'
                    name='remember-me'
                    type='checkbox'
                    className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                    checked={localStorageSession}
                    onChange={handleCheck}
                  />
                  <label htmlFor='remember-me' className='ml-2 block text-sm text-gray-900'>
                    Recordar cuenta
                  </label>
                </div> */}

                {/* <div className='text-sm'>
                  <a href='#' className='font-medium text-indigo-600 hover:text-indigo-500'>
                    Voler
                  </a>
                </div> */}
              </div>

              <div>
                {!successSent ? (
                  <button
                    type='submit'
                    className='flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                  >
                    Mandar código de recuperación
                  </button>
                ) : (
                  <Link
                    to='/codigo_recuperar'
                    type='submit'
                    className='flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                  >
                    Continuar
                  </Link>
                )}
              </div>
              <DevTool control={control} />
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default RecPass
