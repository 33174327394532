// @ts-nocheck

import { useContext, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Link, useLocation, useParams } from 'react-router-dom'
import type { Address, Product } from 'types/api'
import { type State, Store } from 'src/Store'
import { getOrderDetail } from 'src/api/order'
import { getAdminData } from 'src/api/contact'
import { reemplazarSlashPorGuionBajo } from 'src/utils/object'

export default function OrderSummary() {
  const location = useLocation()
  const params = useParams()
  const { number } = params
  const { state }: { state: State } = useContext(Store)
  const { data, error, isLoading } = useQuery(['orders', number], () => getOrderDetail(number), {
    retry: 3,
    retryDelay: 3000,
    networkMode: 'offlineFirst',
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: true,
  })

  const subTotal = data?.products
    ?.map((product) => parseFloat(product.prec.replace(/,/g, '.')) * parseInt(product.cant) * 1.16)
    .reduce((partialSum, a) => partialSum + a, 0)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className='bg-white'>
        <div className='mx-auto max-w-3xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8'>
          <div className='max-w-xl'>
            <h1 className='text-base font-medium text-indigo-600'>Gracias!</h1>
            <p className='mt-2 text-4xl font-bold tracking-tight sm:text-5xl'>
              Pedido comprado con éxito!
            </p>
            <p className='mt-4 text-base text-gray-500'>
              Su orden #{data?.orderNumber} está siendo procesada y estará pronto con usted.
            </p>
            <dl className='mt-12 text-sm font-medium'>
              <dt className='text-gray-900'>
                Para cualquier duda o aclaración, no dude en ponerse en contacto con nosotros
              </dt>
              {/* <a href={`mailto:${adminData?.email}`}>
                <dd className='mt-2 underline text-blue-600'>{adminData?.email}</dd>
              </a> */}
            </dl>
            {/* <dl className='mt-12 text-sm font-medium'>
              <dt className='text-gray-900'>Número de rastreo</dt>
              <dd className='mt-2 text-indigo-600'>{data?.trackingGuide}</dd>
            </dl> */}
          </div>

          <div className='mt-10 border-t border-gray-200'>
            <h2 className='sr-only'>Your order</h2>

            <h3 className='sr-only'>Items</h3>
            {data?.products?.map((product) => (
              <div key={product.prod} className='flex space-x-6 border-b border-gray-200 py-10'>
                {/* <img
                  src={require(`../components/img/${product.code}.jpg`)}
                  alt={product.prod}
                  className='h-20 w-20 flex-none rounded-lg bg-gray-100 object-cover object-center sm:h-40 sm:w-40'
                /> */}
                <div className='flex flex-auto flex-col'>
                  <div>
                    <h4 className='font-medium text-gray-900'>
                      <Link to={`/productos/${reemplazarSlashPorGuionBajo(product.prod)}`}>
                        {product.desc}
                      </Link>
                    </h4>
                    <p className='mt-2 text-sm text-gray-600'>{product.desc}</p>
                  </div>
                  <div className='mt-6 flex flex-1 items-end'>
                    <dl className='flex space-x-4 divide-x divide-gray-200 text-sm sm:space-x-6'>
                      <div className='flex'>
                        <dt className='font-medium text-gray-900'>Cantidad</dt>
                        <dd className='ml-2 text-gray-700'>{product.cant}</dd>
                      </div>
                      <div className='flex pl-4 sm:pl-6'>
                        <dt className='font-medium text-gray-900'>Precio</dt>
                        <dd className='ml-2 text-gray-700'>
                          $
                          {Number(product.prec?.replace(/,/g, '.'))
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            ))}

            <div className='sm:ml-40 sm:pl-6'>
              <h3 className='sr-only'>Your information</h3>

              <h4 className='sr-only'>Addresses</h4>
              {
                <dl className='grid grid-cols-2 gap-x-6 py-10 text-sm'>
                  <div>
                    <dt className='font-medium text-gray-900'>Domicilio de envío</dt>
                    <dd className='mt-2 text-gray-700'>
                      <address className='not-italic'>
                        <span className='block'>{data?.shipName}</span>
                        <span className='block'>
                          {' '}
                          {data?.shipInt} {data?.shipExt} {data?.shipStreet}, {data?.shipNeighbor},{' '}
                          {data?.postCode}
                        </span>
                        <span className='block'>
                          {data?.shipCity}, {data?.shipState}
                        </span>
                        <span className='block'> {data?.shipPhone}</span>
                      </address>
                    </dd>
                  </div>
                  {/* <div>
                    <dt className='font-medium text-gray-900'>Método de pago</dt>
                    <dd className='mt-2 text-gray-700'>
                      {datos && (
                        <>
                          {' '}
                          <p className='capitalize'>{datos?.payment_type_id?.replace(/_/g, ' ')}</p>
                          <p className='capitalize'>{datos?.payment_method_id}</p>
                          <p>
                            <span aria-hidden='true'>••••</span>
                            <span className='sr-only'>Termina con </span>
                            {datos?.card?.last_four_digits}
                          </p>
                        </>
                      )}
                    </dd>
                  </div> */}
                  {/* <div>
                    <dt className='font-medium text-gray-900'>Billing address</dt>
                    <dd className='mt-2 text-gray-700'>
                      <address className='not-italic'>
                        <span className='block'>Kristin Watson</span>
                        <span className='block'>7363 Cynthia Pass</span>
                        <span className='block'>Toronto, ON N3Y 4H8</span>
                      </address>
                    </dd>
                  </div> */}
                </dl>
              }
              {/* 
              <h4 className='sr-only'>Payment</h4>
              {data && (
                <dl className='grid grid-cols-2 gap-x-6 border-t border-gray-200 py-10 text-sm'>
                  {/* <div>
                    <dt className='font-medium text-gray-900'>Método de pago</dt>
                    <dd className='mt-2 text-gray-700'>
                      <p className='capitalize'>{data?.payment_type_id?.replace(/_/g, ' ')}</p>
                      <p className='capitalize'>{data?.payment_method_id}</p>
                      <p>
                        <span aria-hidden='true'>••••</span>
                        <span className='sr-only'>Termina con </span>
                        {data?.card?.last_four_digits}
                      </p>
                    </dd>
                  </div> */}
              {/* <div>
                    <dt className='font-medium text-gray-900'>Envío method</dt>
                    <dd className='mt-2 text-gray-700'>
                      <p>DHL</p>
                      <p>Takes up to 3 working days</p>
                    </dd>
                  </div> */}
              {/* </dl>
              )}  */}

              <h3 className='sr-only'>Summary</h3>

              {state && (
                <dl className='space-y-6 border-t border-gray-200 pt-10 text-sm'>
                  <div className='flex justify-between'>
                    <dt className='font-medium text-gray-900'>Subtotal</dt>
                    <dd className='text-gray-700'>${subTotal || '0.00'}</dd>
                  </div>
                  {/* <div className='flex justify-between'>
                  <dt className='flex font-medium text-gray-900'>
                    Discount
                    <span className='ml-2 rounded-full bg-gray-200 px-2 py-0.5 text-xs text-gray-600'>
                      STUDENT50
                    </span>
                  </dt>
                  <dd className='text-gray-700'>-$18.00 (50%)</dd>
                </div> */}
                  <div className='flex justify-between'>
                    <dt className='font-medium text-gray-900'>Envío</dt>
                    <dd className='text-gray-700'>
                      ${data?.shipCost?.replace(/,/g, '.') ?? '0.00'}
                    </dd>
                  </div>
                  <div className='flex justify-between'>
                    <dt className='font-medium text-gray-900'>Total</dt>
                    <dd className='text-gray-900'>
                      $
                      {subTotal && data && data?.shipCost
                        ? subTotal + parseFloat(data?.shipCost?.replace(/,/g, '.'))
                        : subTotal ?? '0.00'}
                    </dd>
                  </div>
                </dl>
              )}
            </div>
          </div>
          <div className='mt-5 flex flex-row-reverse space-x-4 space-x-reverse sm:mt-6'>
            <Link
              to='/historial'
              className='inline-flex w-1/2 justify-center rounded-md bg-adaflex-100 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
            >
              Ir a pedidos
            </Link>
            <Link
              to='/'
              className='inline-flex w-1/2 justify-center rounded-md bg-adaflex-100 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
            >
              Ir a inicio
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
