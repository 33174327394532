import axios from 'axios'

const BASE_URL = `${process.env.REACT_APP_BASE_URL as string}`
// const BASE_URL = 'https://mundohules.adaflex.mx:4430/datasnap/rest/TSMREST'

export type Billing = {
  noPedido: string
  nombreFiscal: string
  regimenFiscal: string
  rfc: string
  cp: string
  formaPago: string
  usoCfdi: string
}

type Response = {
  status: string
  message: string
}

export const generarCFDI = async (body: Billing) => {
  const response = await axios.post<Response>(`${BASE_URL}/generarCFDI`, body)
  return response.data
}
