import axios from 'axios'

// const BASE_URL = 'https://mundohules.adaflex.mx:4430/datasnap/rest/TSMREST'
const BASE_URL = `${process.env.REACT_APP_BASE_URL as string}`

type ProductsOrder = {
  code: string
  quantity: string
  price: string
}

export type Order = {
  username: string
  shipCost: string
  proveedorPagoId: string
  products: ProductsOrder[]
}

export type Cancel = {
  nodocumento: string
  accion: string
  usuario: string
}

type OrderResponse = {
  status: string
  message?: string
  orders?: {
    orderNumber: string
    orderDate: string
    orderStatus: string
    orderBilling: boolean
    trackingGuide: string
  }[]
}
type Response = {
  status: string
  number: string
}

type ResponseCancel = {
  status: string
  message: string
}

export type Data = {
  billingName: string
  billingNumber: string
  orderDate: string
  orderNumber: string
  orderStatus: string
  deliveryDate: string
  deliveryProvider: string
  postCode: string
  proveedorPagoId: string
  products: {
    prod: string
    cant: string
    prec: string
    desc: string
  }[]
  shipCity: string
  shipCost: string
  shipCountry: string
  shipExt: string
  shipInt: string
  shipName: string
  shipNeighbor: string
  shipPhone: string
  shipState: string
  shipStreet: string
  status: string
  total: string
  trackingGuide: string
}

export const createOrder = async (body: Order) => {
  const response = await axios.post<Response>(`${BASE_URL}/NewOrder`, body)
  return response.data
}

export const getOrders = async (user: string) => {
  const response = await axios.get<OrderResponse>(`${BASE_URL}/getOrders/${user}`, {
    timeout: 2000,
  })
  return response.data
}

export const getOrderDetail = async (order?: string) => {
  if (order) {
    const response = await axios.get<Data>(`${BASE_URL}/getOrderDetail/${order}`, {
      timeout: 2000,
    })
    return response.data
  }
}

export const cancelOrder = async (body: Cancel) => {
  const response = await axios.post<ResponseCancel>(`${BASE_URL}/CancelarPedFac`, body)
  return response.data
}
