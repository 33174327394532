import axios from 'axios'
import type { AdaResponse } from 'types/api'

// const BASE_URL = 'https://mundohules.adaflex.mx:4430/datasnap/rest/TSMREST'
const BASE_URL = `${process.env.REACT_APP_BASE_URL as string}`

type SignIn = {
  username: string
  password: string
}

export type User = {
  username?: string
  password?: string
  nombre: string
  apellidoPat: string
  apellidoMat: string
  lada: string
  telefono: string
}

export type UserPassword = {
  username?: string
  password?: string
  newPass?: string
}

export type RecoveryCode = {
  recCode?: string
  newPass?: string
}

export const createUser = async (body: User) => {
  const response = await axios.post<AdaResponse>(`${BASE_URL}/NewUser`, body)
  return response.data
}
export const signIn = async (body: SignIn) => {
  const response = await axios.post<AdaResponse>(`${BASE_URL}/fLogin`, body)
  return response.data
}

export const updateUser = async (body: User) => {
  const response = await axios.post<AdaResponse>(`${BASE_URL}/UpdAccountData`, body)
  return response.data
}

export const updatePassword = async (body: UserPassword) => {
  const response = await axios.post<AdaResponse>(`${BASE_URL}/UpdPassword`, body)
  return response.data
}

export const genCodeRecovery = async (param: string) => {
  const response = await axios.get<AdaResponse>(`${BASE_URL}/getGenCodeRecovery/${param}`)
  return response.data
}

export const recovertyPassword = async (body: RecoveryCode) => {
  const response = await axios.post<AdaResponse>(`${BASE_URL}/RecoveryPassword`, body)
  return response.data
}
