import axios from 'axios'
import type { AdaResponse } from 'types/api'

const BASE_URL = `${process.env.REACT_APP_BASE_URL as string}`
// const BASE_URL = 'https://mundohules.adaflex.mx:4430/datasnap/rest/TSMREST'

export type Shipping = {
  username: string
  password: string
  nombreEnvio: string
  telefonoEnvio: string
  lada: string
  cpEnvio: string
  calleEnvio: string
  numExtEnvio: string
  numIntEnvio: string
  coloniaEnvio: string
  municipioEnvio: string
  estadoEnvio: string
  paisEnvio: string
  ciudadEnvio: string
}

type User = {
  username: string
  password: string
}

type AddressResponse = AdaResponse & {
  shipmentData: Shipping[]
}

export const createShipping = async (body: Shipping) => {
  const response = await axios.post<AdaResponse>(`${BASE_URL}/UpdShipmentData`, body)
  return response.data
}

export const getShipping = async (param: string) => {
  const response = await axios.get<AddressResponse>(`${BASE_URL}/GetShipmentData/${param}`)
  return response.data
}
