import { type QueryFunctionContext } from '@tanstack/react-query'
import axios from 'axios'
import { reemplazarGuionBajoPorSlash } from 'src/utils/object'
import type { GetProduct, GetProductBrand, GetProductGroup, GetProductLine } from 'types/api'

const BASE_URL = process.env.REACT_APP_BASE_URL as string

export const getProduct = async (code: string) => {
  const { data } = await axios.get(`${BASE_URL}/getProductImg/${code}`, { timeout: 20000 })
  return data as GetProduct
}

export const getProductByName = async (adesc: string) => {
  const { data } = await axios.get(`${BASE_URL}/getProductbyname/${adesc}`, { timeout: 20000 })
  return data as GetProduct
}

export const getProductsImg = async (productsQty = 9, page = 1) => {
  const { data } = await axios.get(`${BASE_URL}/getProductsImg/${productsQty}/${page}`)
  return data as GetProduct
}

export const getProductsTree = async (search = 'null', productsQty = 9, page = 1) => {
  const { data } = await axios.get(
    `${BASE_URL}/getProductsTree/${encodeURIComponent(
      reemplazarGuionBajoPorSlash(search),
    )}/${productsQty}/${page}`,
  )
  return data as GetProduct
}
export const getProductsAgru = async (productsQty = 9, page = 1) => {
  const { data } = await axios.get(`${BASE_URL}/getProductsAgru/${productsQty}/${page}`)
  return data as GetProduct
}

export const getCatalogos = async (
  context: QueryFunctionContext<
    [string, string | undefined, number | undefined, number | undefined],
    any
  >,
) => {
  const [key, name, productsQty, page] = context.queryKey
  const { data } = await axios.get(
    `${BASE_URL}/getCatalogos/${name?.toUpperCase() || ''}/${productsQty || 9}/${page || 1}`,
  )
  return data as GetProduct
}

export const getProductsBrand = async (brand: string, productsQty = 9, page = 1) => {
  const filterBrand = brand.toUpperCase()
  const { data } = await axios.get<GetProductBrand>(
    `${BASE_URL}/getCataMarca/${filterBrand}/${productsQty}/${page}`,
  )
  return data
}
export const getProductsBrandAgru = async (brand: string, productsQty = 9, page = 1) => {
  const filterBrand = brand.toUpperCase()
  const { data } = await axios.get<GetProduct>(
    `${BASE_URL}/getCataMarcaAgru/${filterBrand}/${productsQty}/${page}`,
  )
  return data
}

export const getCataAgrupacion = async (cat: string, productsQty = 9, page = 1) => {
  const { data } = await axios.get<GetProductGroup>(
    `${BASE_URL}/getCataAgrupacion/${cat}/${productsQty}/${page}`,
  )
  return data
}
export const getCataLinea = async (cat: string, productsQty = 9, page = 1) => {
  const { data } = await axios.get<GetProductLine>(
    `${BASE_URL}/getCataLinea/${cat}/${productsQty}/${page}`,
  )
  return data
}

export const getCataLineaAgru = async (cat: string, productsQty = 9, page = 1) => {
  const { data } = await axios.get<GetProduct>(
    `${BASE_URL}/getCataLineaAgru/${cat}/${productsQty}/${page}`,
  )
  return data
}
