import { useContext, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Store } from '../Store'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { ExclamationCircleIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { signIn, updatePassword } from '../api/user'
import { updatePasswordFormSchema, type UpdatePasswordFormValues } from '../schemas/updatePassword'
import { classNames } from '../utils/object'
import { zodResolver } from '@hookform/resolvers/zod'
import secureLocalStorage from 'react-secure-storage'
import type { ACTIONTYPE } from '../Store'
import { DevTool } from '@hookform/devtools'

type Context = {
  state: {
    pf: any
    userInfo: {
      username: string
    }
  }
  dispatch: React.Dispatch<ACTIONTYPE>
}

export default function PasswordScreen() {
  const queryClient = useQueryClient()
  const { state, dispatch: ctxDispatch }: Context = useContext(Store)
  const { pf } = state
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<UpdatePasswordFormValues>({
    resolver: zodResolver(updatePasswordFormSchema),

    defaultValues: {
      username: pf.username,
    },
  })

  const [isRevealPwd, setIsRevealPwd] = useState(false)
  const [isRevealConf, setIsRevealConf] = useState(false)
  const [isRevealNewPwd, setIsRevealNewPwd] = useState(false)
  const [isRevealNewConf, setIsRevealNewConf] = useState(false)

  const mutation = useMutation(updatePassword, {
    onSuccess: async (data, variables) => {
      const { username, newPass } = variables
      await userData.mutateAsync({
        username: username as string,
        password: newPass as string,
      })

      void queryClient.invalidateQueries(['users'])
    },
    onError: (e) => {
      toast.error('No se pudo actualizar la contraseña')
      console.error(e, 'ERROR')
      toast.clearWaitingQueue()
    },
  })

  const userData = useMutation(signIn, {
    onSuccess: (data, variables) => {
      const { password, username } = variables
      if (data.status === 'Success') {
        ctxDispatch({ type: 'USER_SIGNIN', payload: data })
        secureLocalStorage.setItem('userInfo', JSON.stringify(data))
        secureLocalStorage.setItem('pf', JSON.stringify({ username, password }))
        toast.success('Información correctamente actualizada')
        toast.clearWaitingQueue()
      }
      void queryClient.invalidateQueries(['users'])
      toast.success('Contraseña correctamente actualizada')
      toast.clearWaitingQueue()
    },
    onError: () => {
      toast.error('No se pudo actualizar la contraseña')
      toast.clearWaitingQueue()
    },
  })

  const submitHandler = async (data: UpdatePasswordFormValues) => {
    const { username, password, newPass } = data
    await mutation.mutateAsync({
      username,
      password,
      newPass,
    })
    try {
      await userData.mutateAsync({
        username,
        password: newPass,
      })
      if (userData.data?.status === 'Success') {
        ctxDispatch({ type: 'USER_SIGNIN', payload: userData.data })
        secureLocalStorage.setItem('userInfo', JSON.stringify(userData.data))
        secureLocalStorage.setItem('pf', JSON.stringify({ username, password }))
        toast.success('Información correctamente actualizada')
        toast.clearWaitingQueue()
      }
    } catch (err) {
      toast.error(err instanceof Error)
      toast.clearWaitingQueue()
    }
  }

  function getError(field: keyof UpdatePasswordFormValues) {
    if (errors[field]) {
      return errors[field]?.message
    }
    return undefined
  }

  function passwordValidation(value: string) {
    const password = watch().newPass
    if (password)
      switch (value) {
        case 'length':
          return password.length >= 8
        case 'number':
          return /\d/.test(password)
        case 'case':
          return /[A-Z]/.test(password)
        case 'special':
          return /[@$!%*?&#.:]/.test(password)
        default:
          return
      }
  }

  if (userData.data?.status === 'Error') {
    toast.error(userData.data.message)
    toast.clearWaitingQueue()
  }

  return (
    <>
      <Helmet>
        <title>Contraseña</title>
      </Helmet>
      <form
        className='divide-y divide-gray-200 lg:col-span-9'
        onSubmit={handleSubmit(submitHandler)}
      >
        <input disabled id='username' {...register('username')} className='sr-only' />
        <div className='px-4 py-6 sm:p-6 lg:pb-8'>
          <div>
            <h2 className='text-lg font-medium leading-6 text-gray-900'>Contraseña</h2>
            <p className='mt-1 text-sm text-gray-500'>Actualizar contraseña actual.</p>
          </div>
          <input id='username' {...register('username')} className='sr-only' />
          <div className='mt-6 grid grid-cols-12 gap-6'>
            <div className='col-span-12 sm:col-span-6'>
              <label
                htmlFor='password'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Contraseña
              </label>
              <div className='mt-2 flex rounded-md'>
                <div className='relative flex grow items-stretch focus-within:z-10'>
                  <input
                    type={isRevealPwd ? 'text' : 'password'}
                    id='password'
                    {...register('password')}
                    className={classNames(
                      getError('password')
                        ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                        : 'text-gray-900 ring-gray-300 focus:ring-sky-500 placeholder:text-gray-400',
                      'block w-full rounded-l-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6',
                    )}
                  />
                  {getError('password') && (
                    <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
                      <ExclamationCircleIcon className='h-5 w-5 text-red-500' aria-hidden='true' />
                    </div>
                  )}
                </div>
                <button
                  type='button'
                  onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  className='relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                >
                  {isRevealPwd ? (
                    <EyeSlashIcon className='-ml-0.5 h-5 w-5 text-gray-400' aria-hidden='true' />
                  ) : (
                    <EyeIcon className='-ml-0.5 h-5 w-5 text-gray-400' aria-hidden='true' />
                  )}
                </button>
              </div>
              {getError('password') && (
                <p className='mt-2 text-sm text-red-600' id='email-error'>
                  {getError('password')}
                </p>
              )}
            </div>

            <div className='col-span-12 sm:col-span-6'>
              <label
                htmlFor='confirmPassword'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Confirmar contraseña
              </label>
              <div className='mt-2 flex rounded-md'>
                <div className='relative flex grow items-stretch focus-within:z-10'>
                  <input
                    id='confirmPassword'
                    type={isRevealConf ? 'text' : 'password'}
                    {...register('confirmPassword')}
                    className={classNames(
                      getError('confirmPassword')
                        ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                        : 'text-gray-900 ring-gray-300 focus:ring-sky-500 placeholder:text-gray-400',
                      'block w-full rounded-l-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6',
                    )}
                  />
                  {getError('confirmPassword') && (
                    <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
                      <ExclamationCircleIcon className='h-5 w-5 text-red-500' aria-hidden='true' />
                    </div>
                  )}
                </div>
                <button
                  type='button'
                  onClick={() => setIsRevealConf((prevState) => !prevState)}
                  className='relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                >
                  {isRevealConf ? (
                    <EyeSlashIcon className='-ml-0.5 h-5 w-5 text-gray-400' aria-hidden='true' />
                  ) : (
                    <EyeIcon className='-ml-0.5 h-5 w-5 text-gray-400' aria-hidden='true' />
                  )}
                </button>
              </div>
              {getError('confirmPassword') && (
                <p className='mt-2 text-sm text-red-600' id='email-error'>
                  {getError('confirmPassword')}
                </p>
              )}
            </div>
            <div className='col-span-12 sm:col-span-6'>
              <label
                htmlFor='password'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Contraseña nueva
              </label>
              <div className='mt-2 flex rounded-md'>
                <div className='relative flex grow items-stretch focus-within:z-10'>
                  <input
                    type={isRevealNewPwd ? 'text' : 'password'}
                    id='newPass'
                    {...register('newPass')}
                    className={classNames(
                      getError('newPass')
                        ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                        : 'text-gray-900 ring-gray-300 focus:ring-sky-500 placeholder:text-gray-400',
                      'block w-full rounded-l-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6',
                    )}
                  />
                  {getError('newPass') && (
                    <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
                      <ExclamationCircleIcon className='h-5 w-5 text-red-500' aria-hidden='true' />
                    </div>
                  )}
                </div>
                <button
                  type='button'
                  onClick={() => setIsRevealNewPwd((prevState) => !prevState)}
                  className='relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                >
                  {isRevealNewPwd ? (
                    <EyeSlashIcon className='-ml-0.5 h-5 w-5 text-gray-400' aria-hidden='true' />
                  ) : (
                    <EyeIcon className='-ml-0.5 h-5 w-5 text-gray-400' aria-hidden='true' />
                  )}
                </button>
              </div>
              {getError('newPass') && (
                <p className='mt-2 text-sm text-red-600' id='email-error'>
                  {getError('newPass')}
                </p>
              )}
              <div>
                <p className='my-2 text-sm font-medium leading-6 text-gray-900'>
                  Requisitos de contraseña:
                </p>
                <ul className='max-w-md space-y-1 text-gray-500 text-sm list-inside dark:text-gray-400'>
                  <li className='flex items-center'>
                    {passwordValidation('length') ? (
                      <svg
                        className='w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                          clipRule='evenodd'
                        ></path>
                      </svg>
                    ) : (
                      <svg
                        className='w-4 h-4 mr-1.5 text-gray-400 flex-shrink-0'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          d='M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z'
                          clipRule='evenodd'
                        ></path>
                      </svg>
                    )}
                    Al menos 8 caracteres
                  </li>
                  <li className='flex items-center'>
                    {passwordValidation('number') ? (
                      <svg
                        className='w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                          clipRule='evenodd'
                        ></path>
                      </svg>
                    ) : (
                      <svg
                        className='w-4 h-4 mr-1.5 text-gray-400 flex-shrink-0'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          d='M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z'
                          clipRule='evenodd'
                        ></path>
                      </svg>
                    )}
                    Al menos un número
                  </li>
                  <li className='flex items-center'>
                    {passwordValidation('case') ? (
                      <svg
                        className='w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                          clipRule='evenodd'
                        ></path>
                      </svg>
                    ) : (
                      <svg
                        className='w-4 h-4 mr-1.5 text-gray-400 flex-shrink-0'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          d='M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z'
                          clipRule='evenodd'
                        ></path>
                      </svg>
                    )}
                    Al menos una letra mayúscula
                  </li>
                  <li className='flex items-center'>
                    {passwordValidation('special') ? (
                      <svg
                        className='w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                          clipRule='evenodd'
                        ></path>
                      </svg>
                    ) : (
                      <svg
                        className='w-4 h-4 mr-1.5 text-gray-400 flex-shrink-0'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          d='M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z'
                          clipRule='evenodd'
                        ></path>
                      </svg>
                    )}
                    Incluya al menos un caracter especial, e.j., ! @ # ?
                  </li>
                </ul>
              </div>
            </div>

            <div className='col-span-12 sm:col-span-6'>
              <label
                htmlFor='confirmNewPass'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Confirmar contraseña nueva
              </label>
              <div className='mt-2 flex rounded-md'>
                <div className='relative flex grow items-stretch focus-within:z-10'>
                  <input
                    id='confirmNewPass'
                    type={isRevealNewConf ? 'text' : 'password'}
                    {...register('confirmNewPass')}
                    className={classNames(
                      getError('confirmNewPass')
                        ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                        : 'text-gray-900 ring-gray-300 focus:ring-sky-500 placeholder:text-gray-400',
                      'block w-full rounded-l-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6',
                    )}
                  />
                  {getError('confirmNewPass') && (
                    <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
                      <ExclamationCircleIcon className='h-5 w-5 text-red-500' aria-hidden='true' />
                    </div>
                  )}
                </div>
                <button
                  type='button'
                  onClick={() => setIsRevealNewConf((prevState) => !prevState)}
                  className='relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                >
                  {isRevealNewConf ? (
                    <EyeSlashIcon className='-ml-0.5 h-5 w-5 text-gray-400' aria-hidden='true' />
                  ) : (
                    <EyeIcon className='-ml-0.5 h-5 w-5 text-gray-400' aria-hidden='true' />
                  )}
                </button>
              </div>
              {getError('confirmNewPass') && (
                <p className='mt-2 text-sm text-red-600' id='email-error'>
                  {getError('confirmNewPass')}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className='mt-4 flex justify-end gap-x-3 p-4 sm:px-6'>
          <button
            type='button'
            className='inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
          >
            Cancelar
          </button>
          {!mutation.isLoading ? (
            <button
              type='submit'
              className='inline-flex justify-center rounded-md bg-sky-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-700'
            >
              Guardar
            </button>
          ) : (
            <button
              disabled
              className='inline-flex justify-center rounded-md bg-sky-700 px-3 py-2 text-sm font-semibold text-white shadow-sm'
            >
              <svg
                aria-hidden='true'
                role='status'
                className='inline w-4 h-4 mr-3 text-white animate-spin'
                viewBox='0 0 100 101'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                  fill='#E5E7EB'
                />
                <path
                  d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                  fill='currentColor'
                />
              </svg>
              Cargando
            </button>
          )}
        </div>
        <DevTool control={control} />
      </form>
    </>
  )
}
