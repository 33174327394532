import React, { useContext, Fragment, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { classNames } from '../utils/object'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Store } from '../Store'
import type { State } from '../Store'
import type { Product } from 'types/api'

type Action = {
  type: 'USER_SIGNOUT'
}
type Context = {
  state: State
  dispatch: React.Dispatch<Action>
}

import { UserCircleIcon } from '@heroicons/react/24/outline'
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  ShoppingBagIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import secureLocalStorage from 'react-secure-storage'

const userNavigation = [
  { name: 'Perfil', href: '/cuenta', to: '/cuenta' },
  { name: 'Pedidos', href: '/historial', to: '/historial' },
  { name: 'Cerrar sesión', href: '/ingresar', to: '/ingresar' },
]

export default function NavBar() {
  const { pathname } = useLocation()
  const navigation = [
    { name: 'Inicio', href: '/', current: pathname === '/' },
    { name: 'Hules', href: '/hules', current: pathname === '/hules' },
    { name: 'Catálogos', href: '/catalogos', current: pathname === '/catalogos' },
    { name: 'Sucursales', href: '/sucursales', current: pathname === '/sucursales' },
    { name: 'Nosotros', href: '/quienessomos', current: pathname === '/quienessomos' },
    // {
    //   name: 'Productos',
    //   href: '/productos',
    //   current: pathname === '/productos',
    // },
  ]

  const { state, dispatch: ctxDispatch }: Context = useContext(Store)
  const { cart, userInfo }: State = state
  const [searchValue, setSearchValue] = useState('')

  const navigate = useNavigate()

  const handleSearch = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    // Redirigir al usuario a la URL de búsqueda cuando se presione "Enter"
    navigate(`/busqueda/${searchValue}`)
  }

  const handleChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setSearchValue(e.target.value)
  }

  const signoutHandler = () => {
    ctxDispatch({ type: 'USER_SIGNOUT' })
    secureLocalStorage.removeItem('userInfo')
    secureLocalStorage.removeItem('pf')
    secureLocalStorage.removeItem('shippingAddress')
    secureLocalStorage.removeItem('paymentMethod')
    secureLocalStorage.removeItem('cartItems')
    secureLocalStorage.clear()
    window.location.reload()

    // window.location.href = '/'
  }

  if (pathname === '/ingresar') return null
  if (pathname === '/registro') return null
  if (pathname === '/recuperar') return null
  if (pathname === '/codigo_recuperar') return null
  function navigateTo(path: string) {
    navigate(path)
  }
  return (
    <>
      {/* Top navigation */}
      <div className='bg-gray-900'>
        <div className='mx-auto flex justify-center h-10 max-w-7xl items-center px-4 sm:px-6 lg:px-8'>
          <span className='text-white text-xs'>
            Seguimos subiendo nuestros productos. Para consultarlos todos, te invitamos a revisar
            nuestros catálogos.
          </span>
        </div>
      </div>
      <Disclosure as='header' className='bg-sky-800'>
        {({ open }) => (
          <>
            <div className='mx-auto max-w-7xl px-2 sm:px-4 lg:divide-y lg:divide-sky-700 lg:px-8'>
              <div className='relative flex h-16 justify-between'>
                <div className='relative z-10 flex px-2 lg:px-0'>
                  <Link to='/' className='flex shrink-0 items-center'>
                    <img
                      className='h-14 w-auto'
                      src='https://mundohules.adaflex.mx:4430/imagenes/mundo-de-hules.png'
                      alt='Mundo de Hules'
                    />
                  </Link>
                </div>
                <div className='relative z-0 flex flex-1 items-center justify-center px-2 sm:absolute sm:inset-0'>
                  <div className='w-full sm:max-w-xs'>
                    <form onSubmit={handleSearch}>
                      <label htmlFor='buscar' className='sr-only'>
                        Buscar
                      </label>
                      <div className='relative'>
                        <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
                          <MagnifyingGlassIcon
                            className='h-5 w-5 text-sky-400'
                            aria-hidden='true'
                          />
                        </div>
                        <input
                          id='buscar'
                          name='buscar'
                          className='block w-full rounded-md border-0 bg-sky-700 py-1.5 pl-10 pr-3 text-sky-300 placeholder:text-sky-400 focus:bg-white focus:text-sky-900 focus:ring-0 focus:placeholder:text-sky-500 sm:text-sm sm:leading-6'
                          placeholder='Buscar'
                          type='text'
                          value={searchValue}
                          onChange={handleChange}
                          autoComplete='off'
                        />
                        <button className='sr-only' type='submit'>
                          Buscar
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className='relative z-10 flex items-center lg:hidden'>
                  {pathname !== '/orden' && (
                    <Link
                      to='/carrito'
                      className='shrink-0 mr-6 rounded-full p-1 text-sky-200 hover:bg-sky-800 hover:text-white focus:bg-sky-900 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-sky-900'
                    >
                      <span className='sr-only'>View notifications</span>
                      <ShoppingBagIcon
                        className='h-6 w-6 flex-shrink-0 text-white'
                        aria-hidden='true'
                      />
                      <div className='absolute top-8 right-14 inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-red-500 text-xs font-bold text-white dark:border-sky-900'>
                        {(cart.cartItems as Product[]).reduce((a: number, c: Product): number => {
                          return Number(a) + c?.quantity
                        }, 0)}
                      </div>
                    </Link>
                  )}

                  <Disclosure.Button className='inline-flex items-center justify-center rounded-md p-2 text-sky-400 hover:bg-sky-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                    <span className='sr-only'>Abrir menú</span>
                    {open ? (
                      <XMarkIcon className='block h-6 w-6' aria-hidden='true' />
                    ) : (
                      <Bars3Icon className='block h-6 w-6' aria-hidden='true' />
                    )}
                  </Disclosure.Button>
                </div>
                <div className='hidden lg:relative lg:z-10 lg:ml-4 lg:flex lg:items-center'>
                  {pathname !== '/orden' && (
                    <Link
                      to='/carrito'
                      className='shrink-0 rounded-full p-1 text-sky-200 hover:bg-sky-800 hover:text-white focus:bg-sky-900 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-sky-900'
                    >
                      <span className='sr-only'>View notifications</span>
                      <ShoppingBagIcon
                        className='h-6 w-6 flex-shrink-0 text-white'
                        aria-hidden='true'
                      />
                      <div
                        className={classNames(
                          !userInfo ? 'top-10 right-44' : 'top-10 right-32',
                          'absolute inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-red-500 text-xs font-bold text-white dark:border-sky-900',
                        )}
                      >
                        {(cart.cartItems as Product[]).reduce((a: number, c: Product): number => {
                          return Number(a) + c?.quantity
                        }, 0)}
                      </div>
                    </Link>
                  )}
                  {!userInfo && (
                    <div className='space-x-4'>
                      <Link
                        to='/ingresar'
                        className='text-sm ml-2 no-underline font-medium text-white hover:text-sky-100'
                      >
                        Ingresar
                      </Link>
                      <Link
                        to='/registro'
                        className='text-sm no-underline font-medium text-white hover:text-sky-100'
                      >
                        Crear una cuenta
                      </Link>
                    </div>
                  )}
                  {userInfo && (
                    <Menu as='div' className='relative ml-4 mt-1 shrink-0'>
                      <div>
                        <Menu.Button className='flex rounded-full text-sm text-white focus:bg-sky-900 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-sky-900'>
                          <span className='sr-only'>Open user menu</span>
                          <UserCircleIcon className='h-8 w-8 text-cyan-50 rounded-full' />
                          <span
                            className={classNames(
                              'rounded-md py-2 pr-3 text-sm font-medium text-white',
                            )}
                          >
                            {userInfo?.nombre}
                          </span>
                          <div className='text-sm font-medium text-sky-400'>
                            {userInfo?.username}
                          </div>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'
                      >
                        <Menu.Items className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) =>
                                item.name === 'Cerrar sesión' ? (
                                  <Link
                                    to={item.to}
                                    onClick={signoutHandler}
                                    className={classNames(
                                      active ? 'bg-sky-100' : '',
                                      'block no-underline  px-4 py-2 text-sm text-sky-700',
                                    )}
                                  >
                                    {item.name}
                                  </Link>
                                ) : (
                                  <Link
                                    to={item.to}
                                    className={classNames(
                                      active ? 'bg-sky-100' : '',
                                      'block no-underline  px-4 py-2 text-sm text-sky-700',
                                    )}
                                  >
                                    {item.name}
                                  </Link>
                                )
                              }
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  )}
                </div>
              </div>

              <nav className='hidden lg:flex lg:space-x-8 lg:py-2' aria-label='Global'>
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    style={{ color: '#fff' }}
                    className={classNames(
                      item.current
                        ? 'bg-sky-900 text-white'
                        : 'text-sky-50 hover:bg-sky-700 hover:text-white',
                      'inline-flex items-center rounded-md py-2 px-3 text-sm font-medium',
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </a>
                ))}
              </nav>
            </div>

            <Disclosure.Panel as='nav' className='lg:hidden' aria-label='Global'>
              {userInfo ? (
                <>
                  <div className='space-y-1 px-2 pb-3 pt-2'>
                    {navigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as='a'
                        href={item.href}
                        className={classNames(
                          item.current
                            ? 'bg-sky-900 text-white'
                            : 'text-sky-100 hover:bg-sky-700 hover:text-white',
                          'block  text-white rounded-md py-2 px-3 text-base font-medium',
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                  <div className='border-t border-sky-700 pb-3 pt-4'>
                    <div className='flex items-center px-4'>
                      <div className='shrink-0'>
                        <UserCircleIcon
                          style={{ color: '#fff' }}
                          className='h-10 w-10 rounded-full'
                        />
                      </div>
                      <div className='ml-3'>
                        <div className='text-base font-medium text-white'>
                          {userInfo.nombre} {userInfo.apellidopat}
                        </div>
                        <div className='text-sm font-medium text-sky-400'>{userInfo.username}</div>
                      </div>
                      {/* <button
                        type='button'
                        className='ml-auto shrink-0 rounded-full bg-sky-800 p-1 text-sky-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-sky-800'
                      >
                        <span className='sr-only'>View notifications</span>
                        <BellIcon className='h-6 w-6' aria-hidden='true' />
                      </button> */}
                    </div>
                    <div className='mt-3 space-y-1 px-2'>
                      {userNavigation.map((item) =>
                        item.name === 'Cerrar sesión' ? (
                          <Disclosure.Button
                            key={item.name}
                            as='a'
                            style={{ color: '#fff' }}
                            href={item.href}
                            onClick={signoutHandler}
                            className='block rounded-md px-3 py-2 text-base font-medium text-sky-50 hover:bg-sky-700 hover:text-white'
                          >
                            {item.name}
                          </Disclosure.Button>
                        ) : (
                          <Disclosure.Button
                            key={item.name}
                            as='a'
                            style={{ color: '#fff' }}
                            // href={item.href}
                            onClick={() => navigateTo(item.to)}
                            className='block rounded-md px-3 py-2 text-decoration-none text-base font-medium text-sky-50 hover:bg-sky-700 hover:text-white hover:cursor-pointer'
                          >
                            {item.name}
                          </Disclosure.Button>
                        ),
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className='space-y-1 px-2 pb-3 pt-2'>
                    {navigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as='a'
                        href={item.href}
                        className={classNames(
                          item.current
                            ? 'bg-sky-900 text-white'
                            : 'text-sky-100 hover:bg-sky-700 hover:text-white',
                          'block  text-white rounded-md py-2 px-3 text-base font-medium',
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                  <div className='border-t border-sky-700 pb-3 pt-4'>
                    <div className='mt-3 space-y-1 px-2'>
                      <Disclosure.Button
                        as='a'
                        style={{ color: '#fff' }}
                        href='/registro'
                        className='block rounded-md px-3 py-2 text-base font-medium text-sky-50 hover:bg-sky-700 hover:text-white'
                      >
                        Crear una cuenta
                      </Disclosure.Button>
                      <Disclosure.Button
                        as='a'
                        style={{ color: '#fff' }}
                        href='/ingresar'
                        className='block rounded-md px-3 py-2 text-base font-medium text-sky-50 hover:bg-sky-700 hover:text-white'
                      >
                        Ingresar
                      </Disclosure.Button>
                    </div>
                  </div>
                </>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  )
}
