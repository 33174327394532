import Industrial from '../img/hules-autocamion.png'
import Tractocamion from '../img/tractocamion.png'
import Soportes from '../img/EAGLE.jpg'
import Hules from '../img/hules-de-suspension.png'
import Gomas from '../img/gomas-automotrices-e-industriales.png'
import Bandas from '../img/bujes-y-tirantes.png'
import Pasillo from '../img/hules_pasillo_antiderrapantes.png'
import Mangueras from '../img/MANGUERAS-MOLDEADAS.jpg'
import Neoprenos from '../img/neoprenos-y-nitrilos.png'
import Empaques from '../img/empaques/EMPAQUE-AUTOADHERIBLE.jpg'
// import HulesCatalogo from '../catalogos/hules-de-suspension.pdf'

const catalogs = [
  {
    id: 1,
    title: 'HULES INDUSTRIALES',
    href: 'http://www.mundodehules.com/catalogos/HULES_INDUSTRIALES_MDH_2016.pdf',
    description: 'Neoprenos, Nitrilos, SBR, Dilectrico, Ambar, Nitrilo Sanitario.',
    imageUrl: Industrial,
    category: { title: 'Industrial', href: '#' },
  },
  {
    id: 2,
    title: 'HULES PASILLO ANTIDERRAPANTES',
    href: 'http://www.mundodehules.com/catalogos/hules_pasillo_antiderrapantes.pdf',
    description:
      'Hules pasillos tramados, Linoleums, Hules pasillos acabado, Rugos, Alfombras, Espaguetti',
    imageUrl: Pasillo,
    category: { title: 'Pasillo', href: '#' },
  },
  {
    id: 3,
    title: 'HEAVY DUTY',
    href: 'http://www.mundodehules.com/catalogos/HULES-TRACTOCAMION-OP.pdf',
    description: 'Núcleos, Bujes, Ganchos, Soportes, Corbatas, Codos, Coples',
    imageUrl: Tractocamion,
    category: { title: 'Carga Pesada', href: '#' },
  },
  {
    id: 4,
    title: 'EMPAQUES',
    href: 'http://www.mundodehules.com/catalogos/EMPAQUES_PLANOS.pdf',
    description:
      'Planos, Puerta, Cajuela, Guardafango, Corrediza, Cabina, Parabrisas, Botaguas, Pico de pájaro',
    imageUrl: Empaques,
    category: { title: 'Empaques planos', href: '#' },
  },
  {
    id: 5,
    title: 'SOPORTES AUTOMOTRICES',
    href: 'https://www.vazloonline.com/',
    description: 'Soportes de motor, Transmisión y bases de amortiguador',
    imageUrl: Soportes,
    category: { title: 'Sportes', href: '#' },
  },
  {
    id: 6,
    title: 'HULES DE SUSPENSIÓN',
    href: 'http://www.mundodehules.com/catalogos/hules-de-suspension.pdf',
    description: 'Suspensión, Bujes y tirantes, Machetas, Barras y tornillos estabilizadores',
    imageUrl: Hules,
    category: { title: 'Hules suspensión', href: '#' },
  },
  {
    id: 7,
    title: 'GOMAS AUTOMOTRICES E INDUSTRIALES',
    href: 'http://www.mundodehules.com/catalogos/gomas-automotrices-e-industriales.pdf',
    description:
      'Radiador, Fuelles, Cubre polvo, Tirantes, Cubre pedales, Topes, Regatones, Sordinas y más',
    imageUrl: Gomas,
    category: { title: 'Gomas', href: '#' },
  },
  {
    id: 8,
    title: 'BANDAS AUTOMOTRICES E INDUSTRIALES',
    href: 'https://na.daycoaftermarket.com/es/',
    description:
      'Audi, Chevrolet, Dodge, Ford, Honda, Hummer, Isuzu, Jeep, Mazda, Nissan, Peugeot, Rambler, Renault, Seat, Toyota, Volkswagen',
    imageUrl: Bandas,
    category: { title: 'Bandas', href: '#' },
  },
  {
    id: 9,
    title: 'MANGUERAS MOLDEADAS',
    href: 'http://www.mundodehules.com/catalogos/Cauplas.pdf',
    description:
      'Refrigeración, Calefacción, Radiador, Combustible, Descarga de gases, Circulación de aire, Automotrices, Equipo pesado.',
    imageUrl: Mangueras,
    category: { title: 'Mangueras', href: '#' },
  },
  {
    id: 10,
    title: 'COMPLEMENTOS INDUSTRIALES',
    href: 'http://www.mundodehules.com/catalogos/COMPLEMENTOS_INDUSTRIALES.pdf',
    description:
      'Botas industriales, Recubrimiento texturizado, Pegamentos industriales, Topes de estacionamiento.',
    imageUrl: Neoprenos,
    category: { title: 'Complementos', href: '#' },
  },
]

export default function Catalogs() {
  return (
    <div className='bg-white py-24 sm:py-32'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='mx-auto max-w-2xl text-center'>
          <h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>Catálogos</h2>
          <p className='mt-2 text-lg leading-8 text-gray-600'>Nuestros catálogos</p>
        </div>
        <div className='mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3'>
          {catalogs.map((post) => (
            <article key={post.id} className='flex flex-col items-start justify-between'>
              <div className='relative w-full'>
                <img
                  src={post.imageUrl}
                  alt=''
                  className='aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]'
                />
                <div className='absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10' />
              </div>
              <div className='max-w-xl'>
                <div className='mt-8 flex items-center gap-x-4 text-xs'>
                  <a
                    href={post.category.href}
                    className='relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100'
                  >
                    {post.category.title}
                  </a>
                </div>
                <div className='group relative'>
                  <h3 className='mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600'>
                    <a href={post.href} target='_blank' rel='noopener noreferrer'>
                      <span className='absolute inset-0' />
                      {post.title}
                    </a>
                  </h3>
                  <p className='mt-5 line-clamp-3 text-sm leading-6 text-gray-600'>
                    {post.description}
                  </p>
                </div>
                <div className='relative mt-8 flex text-red-600 font-semibold items-center gap-x-4'>
                  <a download='AUTOCAMION' href={post.href} className='text-red-600 text-xs'>
                    Dercargar
                  </a>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <a target='_blank' className='text-red-600 text-xs' href={post.href}>
                    Ver
                  </a>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  )
}
