//Dependencias
import { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import secureLocalStorage from 'react-secure-storage'

// Páginas
import Producto from './pages/ProductoIndividual'
import SignIn from './pages/Ingreso'
import Carrito from './pages/CarritoCompras'
import SignUp from './pages/Registro'
import ShippingAddress from './pages/Envio'
import InfoCuenta from './pages/InfoCuenta'
import PasswordScreen from './pages/ActualizarContra'
import PerfilScreen from './pages/ActualizarPerfil'

//Diseño
import './index.css'

// Componentes {process.env.PUBLIC_URL}
import NavBar from './components/NavBar'
import FooterBar from './components/FooterBar'
import FilterProducts from './pages/FilterProducts'
import Historial from './pages/Historial'
// import BuyOrder from './pages/BuyOrder'
import BuyOrder from './pages/BuyOrder'
import OrderSummary from './pages/OrderSummary'
import BillingPage from './pages/Billing'
import RecPass from './pages/RecPass'
import PassCode from './pages/PassCode'
import AddressScreen from './pages/ActualizarDomicilio'
import { AnimatePresence } from 'framer-motion'
import SearchProducts from './pages/SearchProducts'
import FilterAgrup from './pages/FilterAgrup'
import FilterMarc from './pages/FilterMarc'
import FilterLine from './pages/FilterLine'
import FilterType from './pages/FilterType'
import ProductoList from './pages/ProductoIndividualList'
import About from './pages/About'
import Contact from './pages/Contact'
import Catalogs from './pages/Catalogs'
import Landing from './pages/Landing'
import NotFound from './pages/NotFound'

function App() {
  const [userInfo, setUserInfo] = useState<boolean>(false)
  useEffect(() => {
    if (secureLocalStorage.getItem('userInfo') && secureLocalStorage.getItem('pf')) {
      setUserInfo(true)
    } else {
      setUserInfo(false)
    }
  }, [])

  const userData = secureLocalStorage.getItem('userInfo')
  const pf = secureLocalStorage.getItem('pf')
  return (
    <BrowserRouter basename='/'>
      <div className='flex h-screen flex-col'>
        <ToastContainer position='bottom-center' limit={1} />
        <a
          href='https://wa.me/523317358168?text=Necesito+asistencia'
          className='whatsapp_float'
          target='_blank'
          rel='noopener noreferrer'
        >
          <i className='fa fa-whatsapp whatsapp-icon'></i>
        </a>
        <NavBar />
        <main className='flex-1 bg-gray-100'>
          <AnimatePresence>
            <Routes>
              <Route path='/' element={<Landing />} />
              <Route path='/productos/:code' element={<ProductoList />} />
              <Route path='/quienessomos' element={<About />} />
              <Route path='/sucursales' element={<Contact />} />
              <Route path='/catalogos' element={<Catalogs />} />
              <Route path='/hules' element={<FilterAgrup />} />
              <Route path='/hules/:agrupName' element={<FilterMarc />} />
              <Route path='/hules/:agrupName/:marc' element={<FilterLine />} />
              <Route path='/hules/:agrupName/:marc/:line' element={<FilterType />} />
              <Route
                path='/hules/:agrupName/:marc/:line/:tipo/productos'
                element={<FilterProducts />}
              />
              <Route
                path='/hules/:agrupName/:marc/:line/:tipo/productos/:code'
                element={<Producto />}
              />
              <Route path='/busqueda/:name' element={<SearchProducts />} />
              <Route path='/ingresar' element={!userInfo ? <SignIn /> : <Navigate to='/' />} />
              <Route path='/recuperar' element={!userInfo ? <RecPass /> : <Navigate to='/' />} />
              <Route
                path='/codigo_recuperar'
                element={!userInfo ? <PassCode /> : <Navigate to='/' />}
              />
              <Route path='/carrito' element={<Carrito />} />
              <Route path='/registro' element={!userInfo ? <SignUp /> : <Navigate to='/' />} />
              <Route path='/historial' element={<Historial />} />
              {<Route path='/factura/:order' element={<BillingPage />} />}
              <Route
                path='/envio'
                element={userInfo ? <ShippingAddress /> : <Navigate to='/ingresar' />}
              />
              <Route
                path='/orden'
                element={userInfo ? <BuyOrder /> : <Navigate to='/ingresar' />}
              />
              <Route path='/resumen/:number' element={<OrderSummary />} />
              <Route path='*' element={<FilterProducts />} />
              <Route path='/cuenta' element={userInfo ? <InfoCuenta /> : <Navigate to='/' />}>
                <Route index element={userData && pf ? <PerfilScreen /> : <Navigate to='/' />} />
                <Route
                  path='perfil'
                  element={userData && pf ? <PerfilScreen /> : <Navigate to='/' />}
                />
                <Route
                  path='password'
                  element={userInfo ? <PasswordScreen /> : <Navigate to='/' />}
                />
                <Route
                  path='domicilio'
                  element={userInfo ? <AddressScreen /> : <Navigate to='/' />}
                />
              </Route>
              <Route path='*' element={<NotFound />} />
            </Routes>
          </AnimatePresence>
        </main>
        <FooterBar />
      </div>
    </BrowserRouter>
  )
}

export default App
